import React from "react"
import { Link } from "gatsby"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "./Layout"
import SEO from "./Seo"

const AboutPage = () => (
  <Layout>
    <SEO
      title="About me"
      description="I’m a Web Developer with a lot of ambition and desire to work. What I like most about my job is seeing how the projects I am involved with grow."
    />
    <PageTransition>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 700,
          minHeight: `70vh`,
        }}
      >
        <h1>About me</h1>
        <div className="about-p">
          <p>
            I started my career as a computer technician years ago and continued
            studying until I reached the goal to become a programmer. Over time
            I discovered my passion for web and decided to specialize in it by
            doing a Higher Technical Certificate in Web Application Development and a Bootcamp of Web Development.
          </p>
          <p>
            While studying, I started creating static web pages with CMS like
            Wordpress or Prestashop as a freelance for small and medium
            companies, and also with other collaborators such as UX / UI
            designers or SEO consultants. These projects helped me gain
            experience designing web pages as well as managing projects.
          </p>
          <p>
            I am currently focused on Front End technologies, specifically on
            React with Redux and other technologies like Context API, SASS/LESS, Server Side Rendering, and more. Developing in TDD and clean architecture.
          </p>
          {/* <p>One of my goals is to end up leading my own projects and be able to deal with the client, this last one been my main and strongest quality.</p> */}
          {/* <p>I am currently looking for projects and job opportunities that give me value and knowledge in web development. So if you think I can fit into your project, why not contact me? <span role="img" aria-label="Contact me">😬</span></p> */}
        </div>
        <div className="about-p">
          <h2>
            <span role="img" aria-label="Formation">
              🎓
            </span>{" "}
            Formation
          </h2>
          <p>
            Higher Technical Certificate in Web Application Development
            <br />
            IOC, Institut Obert de Catalunya (CFGS - DAW)
          </p>
          <p>
            Higher Technical Certificate in Multi platform Application
            Development
            <br />
            IES Sabadell (CFGS- DAM)
          </p>
          <p>
            Bootcamp Full Stack Development
            <br />
            Ironhack Tech School (Web Dev)
          </p>
          <p>
            Technical Certificate in IT
            <br />
            Escola Joviat (CFGM - SMIX)
          </p>
        </div>
        <div className="about-p">
          <h2>
            <span role="img" aria-label="Courses">
              👨🏼‍💻
            </span>{" "}
            Courses
          </h2>
          <p>
            During my studies as a developer I have always been very curious and
            many technologies have caught my attention. That is the main reason
            I have been taking additional courses and tutorials to learn new
            programming languages, or simply to review those I currently work
            with.
          </p>
          <ul>
            <li>
              <p>
                <u>React</u> (Redux, Hooks & MERN) by Fernando Herrera - 54
                hours.
              </p>
              <div className="categories">
                <div className="category-course">React</div>
                <div className="category-course">Router</div>
                <div className="category-course">Redux</div>
                <div className="category-course">Context API</div>
                <div className="category-course">Hooks</div>
                <div className="category-course">Express.js</div>
                <div className="category-course">Node.js</div>
                <div className="category-course">ES6</div>
                <div className="category-course">Mongo DB</div>
                <div className="category-course">JWT</div>
                <div className="category-course">Jest</div>
                <div className="category-course">SASS</div>
              </div>
            </li>
            <li>
              <p>
                The Great <u>Gatsby</u> Bootcamp by Andrew Mead - 6 hours.
              </p>
              <div className="categories">
                <div className="category-course">React</div>
                <div className="category-course">Gatsby.js</div>
                <div className="category-course">GraphQL</div>
                <div className="category-course">ES6</div>
              </div>
            </li>
            <li>
              <p>
                <u>Docker</u> DevOps by Ricardo André González - 8,5 hours.
              </p>
              <div className="categories">
                <div className="category-course">Docker</div>
                <div className="category-course">Docker Compose</div>
                <div className="category-course">Docker Registry</div>
                <div className="category-course">Containers</div>
                <div className="category-course">Images</div>
                <div className="category-course">Networks</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="about-p">
          <h2>
            <span role="img" aria-label="Courses">
              📚
            </span>{" "}
            Books
          </h2>
          <p>
            During my studies as a developer I have always been very curious and
            many technologies have caught my attention. That is the main reason
            I have been taking additional courses and tutorials to learn new
            programming languages, or simply to review those I currently work
            with.
          </p>
          <p>
            <ul>
              <li>
                Eloquent <u>Javascript</u> Third Edition by Marjin Haverbeke.
              </li>
              <li>
                <u>Clean Architecture</u> by Robert C. Martin.
              </li>
              <li>
                <u>Clean Code</u> by Robert C. Martin.
              </li>
              <li>
                Clean <u>Javascript</u> by Miguel A. Gómez.
              </li>
              <li>
                Desarrollo web con <u>React</u> by Pello Xabier.
              </li>
              <li>
                Hábitos atómicos by James Clear.
              </li>
            </ul>
          </p>
        </div>
        <div className="link-contact">
          <Link className="contact-link underline--magical" to="/contact">
            <span role="img" aria-label="Contact with me">
              👋
            </span>{" "}
            SAY HELLO!
          </Link>
        </div>
      </div>
    </PageTransition>
  </Layout>
)

export default AboutPage
